
//change the scroll design
/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #fffefe;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b5bdf5;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(105, 121, 177);
  }
  
  .customInputStyle {
    height: 38px;
    font-size: 11px;
    border-radius: 4px;
    width: 100%;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  html {
    font-size: 1rem;
  }
  
  @include media-breakpoint-up(xs) {
    html {
      font-size: 0.4rem;
    }
  }
  
  @include media-breakpoint-up(sm) {
    html {
      font-size: 0.5rem;
    }
  }
  
  @include media-breakpoint-up(md) {
    html {
      font-size: 0.8rem;
    }
  }
  
  @include media-breakpoint-up(lg) {
    html {
      font-size: 0.8rem;
    }
  }
  @include media-breakpoint-up(xl) {
    html {
      font-size: 0.8rem;
    }
  }
  
  .zoomIn{
    transform: scale(1.01);
    border:1px solid white;
    background-color: white;
    box-shadow: 5px 10px 20px -6px rgba(117,110,117,0.07);
    z-index: 5000;
    height: 50%;
    padding: 2px;
  }

  
  
  
  
  