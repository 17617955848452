.ourCustomInputStyle {
  height: 38px !important;
  border-radius: 5px !important;
  border-color: #a4b0be !important;
  font-size: 12px !important;
  color: #354655 !important;
  font-family: "poppins" !important;
  font-weight: 200 !important;
}

.ourCustomTextAreaStyle {
  border-radius: 5px !important;
  border-color: #a4b0be !important;
  font-size: 12px !important;
  color: #354655 !important;
  font-family: "poppins" !important;
  font-weight: 200 !important;
}

.defaultSelectStyle .Select__single-value {
  color: red; /* Set the text color to red */
}

.field {
  border: none;
  border-radius: 4px;
  padding: 5px;
  border-radius: 4px;
  width: 20%;
  height: 2em;
  position: relative;
  border-bottom: 1px solid;
  border-color: #5ed8ff;
  margin: 4px;
  overflow: visible;
}

.field:hover {
  background-color: rgba(223, 219, 219, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.disabled-row {
  background-color: "#c8e6c9";
}

.bank-row {
  background-color: #93d5f8f6;
  color: rgba(0, 0, 0, 0.301);
  border: 2rem solid;
}

.header-class {
  background-color: red;
}

.hoverdT:hover {
  color: #26c1fd;
}

.transactionTableCellCustomStyle {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.transactionTableCellCustomStyle1 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: white;
}

.transactionTableCellCustomStyle3 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.hoverCatName {
  cursor: text;
  border-radius: 4px;
  align-items: center;
  padding: 2px;
}

.hoverCatName:hover {
  background-color: #f1f2f6;
}

.arrow1 {
  animation: slide1 1s ease-in-out infinite;
  margin-left: 9px;
}

.arrow2 {
  animation: slide2 1s ease-in-out infinite;
  /* margin-left: 9px; */
}

@keyframes slide2 {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, -20px);
  }
}

@keyframes slide1 {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(10px, 0);
  }
}

.uploadImageFrame {
  border: 1px black dashed;
  border-radius: 4px;
  width: 400px;
  height: 450px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  padding: 1px;
}

.transactionFileImg {
  border-radius: 4px;
  cursor: pointer;
}

.vl {
  border-left: 2px solid #cfcfcf99;
  border-radius: 95%;
  height: 80px;
}

/*Huge thanks to @tobiasahlin at http://tobiasahlin.com/spinkit/ */
.spinner {
  /* margin: 100px auto 0; */
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 8px;
  height: 8px;
  background-color: #2064c4;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
  background-color: #2064c4;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  background-color: #2064c4;
}

.spinner_ > div {
  width: 8px;
  height: 8px;
  background-color: #ffffff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner .bounce1_ {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
  background-color: #ffffff;
}

.spinner .bounce2_ {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  background-color: #ffffff;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.react-bootstrap-table .table {
  width: 100%;
  height: auto;
  /* border: lightgray 1px solid; */
}

.cardbg {
  background-image: linear-gradient(to left, red, orange);
}

.modal-lg {
  width: 60vw;
}

.dropdown-menu{
  color: #354655 !important;
}
