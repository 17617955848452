//
// Vertical navbar + Sidenav
//
.navbar-vertical {
    .navbar-brand-img,
    .navbar-brand > img {
        max-width: 100%;
        max-height: 2rem;
    }


    // Navbar navigaton
    .navbar-nav {
        margin-left: -$navbar-padding-x;
        margin-right: -$navbar-padding-x;

        // Navbar link
        .nav-link {
            padding-left: $navbar-padding-x;
            padding-right: $navbar-padding-x;
            font-size: $navbar-nav-link-font-size;
            font-weight: $navbar-nav-link-font-weight;

            &.active {
                position: relative;

            }

            // Icon
            > i {
                min-width: $navbar-icon-min-width;
                font-size: .9375rem;
                line-height: ($font-size-base * $line-height-base);
            }

            // Dropdown
            .dropdown-menu {
                border: none;

                .dropdown-menu {
                    margin-left: $dropdown-item-padding-x / 2;
                }
            }
        }
        .nav-sm {
            .nav-link {
                font-size: .8125rem;
            }
        }
    }


    // Navbar navigation

    .navbar-nav .nav-link {
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .navbar-nav .nav-link[data-toggle="collapse"] {
        &:after {
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-family: 'Font Awesome 5 Free';
            font-weight: 700;
            content: "\f105";
            margin-left: auto;
            color: $gray-400;
            @include transition($transition-base);
        }

        // Expanded
        &[aria-expanded="true"] {
            &:after {
                color: theme-color("primary");
                transform: rotate(90deg);
            }
        }
    }

    // Second level
    .navbar-nav .nav .nav-link {
        padding-left: $navbar-padding-x + $navbar-icon-min-width;
    }

    // Third level
    .navbar-nav .nav .nav .nav-link {
        padding-left: $navbar-padding-x * 1.5 + $navbar-icon-min-width;
    }

    // Navbar heading
    .navbar-heading {
        padding-top: $nav-link-padding-y;
        padding-bottom: $nav-link-padding-y;
        font-size: $font-size-xs;
        text-transform: uppercase;
        letter-spacing: .04em;
    }

    // Expanded navbar specific styles
    &.navbar-expand {
        @each $breakpoint, $dimension in $grid-breakpoints {
            &-#{$breakpoint} {
                @include media-breakpoint-up(#{$breakpoint}) {
                    display: block;
                    position: fixed;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    max-width: $navbar-vertical-width;
                    overflow-y: auto;
                    padding-left: 0;
                    padding-right: 0;

                    .navbar-inner {
                        padding-left: $navbar-vertical-padding-x;
                        padding-right: $navbar-vertical-padding-x;
                    }

                    // Container
                    >[class*="container"] {
                        flex-direction: column;
                        align-items: stretch;
                        min-height: 100%;
                        padding-left: 0;
                        padding-right: 0; // Target IE 10 & 11
                        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                            min-height: none;
                            height: 100%;
                        }
                    }

                    .navbar-nav {
                        flex-direction: column;
                        margin-left: -$navbar-vertical-padding-x;
                        margin-right: -$navbar-vertical-padding-x;
                    }

                    .navbar-nav .nav-link {
                        padding: $navbar-vertical-nav-link-padding-y $navbar-vertical-nav-link-padding-x;
                    }

                    .navbar-nav > .nav-item {
                        margin-top: 2px;

                        > .nav-link {
                            &.active {
                                background: $gray-100;
                                margin-right: .5rem;
                                margin-left: .5rem;
                                padding-left: 1rem;
                                padding-right: 1rem;
                                border-radius: $border-radius;
                            }
                        }
                    }

                    .lavalamp-object {
                        width: calc(100% - 1rem) !important;
                        background: theme-color("primary");
                        color: color-yiq($primary);
                        margin-right: .5rem;
                        margin-left: .5rem;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        border-radius: $border-radius;
                    }

                    // Second level
                    .navbar-nav .nav .nav-link {
                        padding-top: $navbar-vertical-nav-link-padding-y / 1.5;
                        padding-bottom: $navbar-vertical-nav-link-padding-y / 1.5;
                        padding-left: 15px;
                        span.sidenav-normal{
                          padding-left: 8px;
                          transition: all .1s ease 0s;
                        }

                        span.sidenav-mini-icon{
                          width: 34px;
                          text-align: center;
                          transition: all .1s ease 0s;
                        }
                    }


                    // Third level
                    .navbar-nav .nav .nav .nav-link {
                        padding-left: $navbar-vertical-padding-x * 1.5 + $navbar-icon-min-width;

                    }
                }
            }
        }
    }
}

.sidenav-header {
    height: 78px;
}

.g-sidenav-pinned {
    .sidenav {
        max-width: $navbar-vertical-open-width !important;

        .navbar-brand,
        .navbar-heading {
            display: block;
        }
    }
}
